<template>
  <card :title="title" id="details" v-if="canEdit || canDelete" v-cloak>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-4 text-left">
        <label class="control-label">{{ $t("common.name") }}</label>
        <input
          type="text"
          v-model="data.name"
          :placeholder="$t('common.name')"
          :class="{ 'field-error': $v.data.name.$error }"
          class="form-control"
        />
      </div>
    </div>    
    <div class="row">
      <div class="col-sm-12 col-lg-4 form-group">
        <label class="control-label">{{
          $t("stock_management.products.type")
        }}</label>
        <v-select
          v-model="data.product_type"
          :options="product_type_list"
          :reduce="(option) => option"
          :class="{ 'field-error': $v.data.product_type.$error }"
          :placeholder="$t('stock_management.products.select_product_type')"
          label="name"
          taggable
          autocomplete="off"
          @input="onProductTypeSelected"
          class="custom-select-dropdown-height"
          :disabled="!data.is_editable"
        ></v-select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-4 form-group">
        <label class="control-label">{{
          $t("stock_management.products.select_mu")
        }}</label>
        <v-select
          v-model="data.measure_unit"
          :reduce="(option) => option"
          :class="{ 'field-error': $v.data.measure_unit_id.$error }"
          :placeholder="$t('stock_management.products.select_mu')"
          label="name"
          autocomplete="off"
          :options="measure_unit_list"
          @input="onMeasureUnitSelected"
          class="custom-select-dropdown-height"
          :disabled="!data.is_editable"
        ></v-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-4 text-left">
        <label class="control-label">{{
          $t("stock_management.products.barcode")
        }}</label>
        <input
          type="text"
          v-model="data.barcode"
          :placeholder="$t('stock_management.products.barcode')"
          class="form-control"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-4 text-left">
        <label class="control-label">{{
          $t("stock_management.products.code")
        }}</label>
        <input v-model="data.code" class="form-control" placeholder="" />
      </div>
    </div>
    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>
  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { only_price } from "@/helpers/common";
import required from "vuelidate/src/validators/required";
import {
  canDelete,
  canEdit,
  permissions,
  permissionTypes,
} from "@/helpers/Permissions";
import * as actions from "@/helpers/generalActions";
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";
import vSelect from "vue-select";

export default {
  name: "SMProductsDetails",
  components: {
    Forbidden,
    AgroActionButtons,
    vSelect,
  },
  data() {
    return {
      data: {
        is_editable: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      users: "user/users",
      products: "smProducts/smProducts",
      productTypes: "smProductTypes/smProductTypes",
      measureUnits: "measureUnits/measureUnits",
    }),
    name() {
      return this.$t("stock_management.products.title");
    },
    config() {
      return {
        permission: permissions.SM_PRODUCTS,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: "/stock-management/sm-products",
        confirm_delete: true,
      };
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this) && this.data.is_editable;
    },
    title() {
      return (
        (this.data.uuid ? this.$t("common.edit") : this.$t("common.new")) +
        " " +
        this.name
      );
    },
    measure_unit_list() {
      if (this.measureUnits) {
        return this.measureUnits.map((item) => ({
          value: item.id,
          name: `${item.name} (${item.symbol})`,
        }));
      }
      return [];
    },
    product_type_list() {
      if (this.productTypes) {
        return this.productTypes.map((item) => ({
          value: item.uuid,
          name: item.name,
        }));
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      get: "smProducts/getSmProduct",
      create: "smProducts/createSmProduct",
      update: "smProducts/updateSmProduct",
      delete: "smProducts/deleteSmProduct",
      getMeasureUnits: 'measureUnits/searchMeasureUnits',
    }),
    loadData() {
      let vm = this;
      return this.actions.getData(
        this,
        (data) => {
          vm.$set(vm.data, "vat_code", null);
        },
        (data) => {}
      );
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    save() {
      return this.actions.save(this, this.config.list_url);
    },
    saveAndNew() {
      return this.actions.saveAndNew(this, "add");
    },
    onlyPrice(event) {
      return only_price(event);
    },
    onProductTypeSelected(data) {
      if (data) {
        this.data.product_type = {
          uuid: data?.value ?? null,
          name: data.name,
        };
      } else {
        this.data.product_type = null;
      }
    },
    onMeasureUnitSelected(data) {
      if (data) {
        this.data.measure_unit = {
          id: data.value,
          name: data.name,
        };
        this.data.measure_unit_id = data.value;
      } else {
        this.data.measure_unit = null;
        this.data.measure_unit_id = null;
      }
    },
  },
  mounted() {
    this.getMeasureUnits({
      enablePagination: 0,
    });
    this.loadData();
    this.actions.handleParams(this);
  },
  watch: {
    $route: {
      handler: function (newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    permission: {
      handler: function (newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    },
    "data.price_net": (data) => {},
    data: {
      handler: (data) => {},
      deep: true,
    },
  },
  validations() {
    return {
      data: {
        name: {
          required,
        },
        product_type: {
          required,
        },
        measure_unit_id: {
          required,
        },
      },
    };
  },
};
</script>

<style scoped>
.custom-select-dropdown-height >>> .vs__dropdown-menu {
  max-height: 200px; /* Set your desired dropdown height here */
  overflow-y: auto;  /* Enable vertical scrolling if content exceeds max-height */
}
</style>