<template>
    <div>
        <DxDataGrid 
            :ref="contractItemGridRefKey"
            :data-source="templateData.data.contract_items"
            :show-borders="true"
            :remote-operations="false"
            @row-updating="tariffSistemUpdating"
        >
            <DxColumn
                data-field="owner.uuid"
                data-type="string"
                caption="Proprietar"
                edit-cell-template="dropDownBoxEditor"
            >
            <DxLookup
                :data-source="owners"
                display-expr="name"
                value-expr="uuid"
            />
            </DxColumn>
            <template #dropDownBoxEditor="{ data: cellInfo }">
                <OwnerEditorTemplate
                :value="cellInfo.value"
                :onValueChanged="cellInfo.setValue"
                :dataSource="owners"
                :loadDataSourceOwners="loadOwners"
                />
            </template>
            <DxColumn
                data-field="area.name"
                data-type="string"
                caption="Parcela"
            />
            <DxColumn
                data-field="area.owned_surface_absolute"
                data-type="string"
                caption="Suprafata"
                :calculate-cell-value="getSurface"
            />
            <DxColumn
                data-field="start_date"
                data-type="string"
                caption="Data inceput"
            />
            <DxColumn
                data-field="end_date"
                data-type="string"
                caption="Data sfarsit"
            />
            
            <DxPaging :total="templateData.data.contract_items.length" :page-size="15"/>
            <DxPager
                :visible="true"
                :allowed-page-sizes="pageSizes"
                display-mode="compact"
                :show-page-size-selector="true"
                :show-info="true"
                :show-navigation-buttons="true"
            />
            <DxEditing
                refresh-mode="full"
                :allow-adding="false"
                :allow-updating="true"
                :allow-deleting="false"
                :use-icons="true"
                mode="row"
            />
        </DxDataGrid>
    </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import * as endpoints from '../../../store/api/endpoints'
import { DxDataGrid, DxColumn, DxPaging, DxPager, DxEditing, DxLookup, DxRequiredRule } from 'devextreme-vue/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import Forbidden from "~/pages/errors/Forbidden.vue";
import * as api from '../../../store/api/index'
import OwnerEditorTemplate from './ownerEditorTemplate.vue'

export default {
    name: "ContractItems",
    middleware: ['auth', 'check-company-admin'],
    data(){
        return {
            contractItemGridRefKey: 'contract-item-grid-' + this.templateData.key,
            
            owners:[],
            productsData: [],
            measureUnitsData: [],
            pageSizes: [15, 25, 100]
        };
    },
    props:{
        templateData: {
            type: Object,
            default: null
        }
    },
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxPager,
        DxEditing,
        DxLookup,
        DxRequiredRule,
        Forbidden,
        OwnerEditorTemplate
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            users: 'user/users'
        }),
        canEdit() {
            return canEdit(this);
        },
        tariffSystemGrid: function() {
            return this.$refs[contractItemGridRefKey].instance;
        },
    },
    methods: {
        ...mapActions({
            getOwners: 'leasecontracts/getOwners',
            updateOwnerAndSurface: 'leasecontracts/updateOwnerAndSurface'
        }),
        getSurface(data){
            return data.area.owned_surface_absolute + ' ha';
        },
        tariffSistemUpdating(e){
            let itemToUpdate = {
                contract_item_uuid: e.key.uuid,
                owner_uuid: e.newData.owner?.uuid ? e.newData.owner.uuid : null,
                surface: e.newData.area.owned_surface_absolute ? e.newData.area.owned_surface_absolute : null
            };
            this.updateOwnerAndSurface(itemToUpdate).then(response => {

            });
            console.log('tarif sistem updating -> ', e, itemToUpdate);
        },
        async loadTariffSystemItems(loadOptions){
            let pageLimit = loadOptions.take;
                let pageNumber = (loadOptions.skip + loadOptions.take)/pageLimit;
                
                let dataSource =  {
                    data:[],
                    totalCount:0
                };
                let payload = {
                    page: pageNumber,
                    limit: pageLimit,
                    tariff_system_uuid: this.templateData.data.uuid,
                    sortBy: 'uuid'
                }
                await api.fetchFull(endpoints.LEASE_CONTRACT_TARIFF_SYSTEM_ITEM, payload, null,(response) => {
                    dataSource.data = response.data.data;
                    dataSource.totalCount = response.data.pagination.total
                });

                return dataSource;
        },
        insert(obj){
             obj.tariff_system_uuid = this.templateData.data.uuid;
             obj.product_uuid = obj.product.uuid;
             obj.measure_unit_uuid = obj.measure_unit.uuid;
            this.createTariffSystemItem(obj).then(response => {
                this.tariffSystemGrid.refresh();
            });
        },
        update(key, obj){
            let oldObj = {
                tariff_system_item_uuid: key,
                uuid: key,
                tariff_system_uuid: this.templateData.data.tariff_system_uuid,
                product_uuid: '',
                quantity: 0,
                measure_unit_uuid: ''
            };
            this.getTariffSystemItemByUUID({uuid: key}).then(response => {
                oldObj.product_uuid = obj.product ? obj.product.uuid : response.data.product.uuid;
                oldObj.quantity = obj.quantity ? obj.quantity : response.data.quantity;
                oldObj.measure_unit_uuid = obj.measure_unit ? obj.measure_unit.uuid : response.data.measure_unit.uuid;
            });
            this.updateTariffSystemItem(oldObj).then(response => {
                this.tariffSystemGrid.refresh();
            });
        },
        delete(key){
            let obj = {uuid: key, product_price_uuid: key}
            this.deleteTariffSystemItem(obj).then(response => {
                this.tariffSystemGrid.refresh();
            });
        },
        loadOwners(){
            this.getOwners({}).then(response => {
                this.owners = response.data;
            });
        }
    },
    mounted(){
        this.loadOwners();
        
    }
}

</script>