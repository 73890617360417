<template>
  <card :title="title" id="details" v-if="canEdit || canDelete" v-cloak>
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg"  :title="title" @click="newObject()"/>
    </span>
    <div class="row">
      <div class="col-sm-6 col-lg-6 form-group">
        <label class="control-label">{{$t("common.warehouse")}}</label>
        <b-form-select
          v-model="data.warehouse_uuid"
          class="form-control"
          :class="{ 'field-error' : $v.data.warehouse_uuid.$error }"
          :options="warehouse_list"></b-form-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">{{ $t('stock_management.sales.product_name') }}</label>
        <v-select
          v-model="productNameSearch"
          :class="{ 'field-error' : this.$v.data.product.name.$error }"
          :placeholder="$t('stock_management.sales.type_product_name')"
          autocomplete="off"
          @option:selected="onProductNameSelected"
          ref="product_name"
          taggable
          :options="product_name_list"></v-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">
          <span>{{ $t('stock_management.sales.unit_price') }}</span>
          <span class="fyi-price text-primary" v-if="data.product.uuid && stock_product_info && stock_product_info.price_net > 0">
            {{ $t('stock_management.sales.stored_price', { price: stock_product_info.price_net.toFixed(2) +' '+ $currency.name }) }}
          </span>
        </label>
        <input v-model="data.product.unit_price"
               class="form-control upper"
               :class="{ 'field-error' : $v.data.product.unit_price.$error }"
               v-on:keydown="onlyPrice"
               placeholder="0.00"/>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 col-lg-3 text-left">
        <label class="control-label">
          <span>{{ $t('stock_management.products.quantity') }}</span>
        </label>
        <span class="font-small text-primary" v-if="productNameSearch && productNameSearch.value">
          {{ $t('stock_management.products.available_quantity') }}:
          {{ this.getStockCount(this.data.warehouse_uuid) }} {{ data.product.measure_unit.name }}
        </span>
        <span class="mu">{{ data.product.measure_unit ? data.product.measure_unit.name : ''}} </span>
        <input v-model="data.product.quantity"
               class="form-control upper"
               :class="{ 'field-error' : $v.data.product.quantity.$error }"
               v-on:keydown="onlyNumbers"
               placeholder="0.00"/>
        <span class="text-danger small-font">{{ maxQtyError }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-lg-6 form-group">
        <label class="control-label">{{ $t("stock_management.sales.client") }}</label>
        <autocomplete-search
          :placeholder="$t('stock_management.sales.client_search')"
          :dispatch="getPartners"
          search-key="name"
          ref="partner"
          :required="true"
          :data="partnerName"
          @onSelected="onPartnerSelected"
          @onChange="onPartnerChanged"
        ></autocomplete-search>
        <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg float-right text-success c-pointer"
             @click="showPartnerDialog" v-if="addNewPartner === 'add'"/>
        <img src="~@/assets/images/desktop/icons/controls/edit.svg" class="icon-svg float-right text-success c-pointer"
             @click="showPartnerDialog" v-if="addNewPartner === 'edit'"/>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <label class="control-label font-weight-bold">{{$t("common.total")}}</label>
        <div>{{ total +' '+ $currency.name }}</div>
      </div>
    </div>

    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>

    <CreatePartnerDialog :show="showPartnersModal" @close="showPartnersModal = false"
                         :data="partnersModalData"
                         @onOK="onPartnerDialogClosed"
    ></CreatePartnerDialog>

  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  buildDefaultAutocompleteList,
  buildDefaultDropdownList, errorMessage, only_numbers, only_price
} from '@/helpers/common'
import required from "vuelidate/src/validators/required";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import * as actions from '@/helpers/generalActions'
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import AutocompleteSearch from "~/components/Common/AutocompleteSearch/Index.vue";
import CreatePartnerDialog from "~/pages/stock-management/sm-stocks/partials/CreatePartnerDialog.vue";

export default {
  name: 'Sales',
  components: {
    CreatePartnerDialog,
    AutocompleteSearch,
    Forbidden,
    AgroActionButtons,
    VueBootstrapTypeahead
  },
  data() {
    return {
      data: { product: {} },
      product_types: this.productTypes ? this.productTypes : [],
      productBarcodeList: [],
      productBarcodeSearch: '',
      selectedProductBarcode: null,
      productNameList: [],
      productNameSearch: '',
      selectedProductName: null,
      productList: [],
      addNewPartner: false,
      showPartnersModal: false,
      partnersModalData: {},
      maxQtyError: '',
      stock_product_info: null,
      partnerInfo: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
      domain: 'auth/domain',
      products: 'smProducts/smProducts',
      productTypes: 'smProductTypes/smProductTypes',
      warehouses: 'smWarehouses/smWarehouses',
      partners: 'smPartners/smPartners',
      measureUnits: 'measureUnits/measureUnits',
      stockProductInfo: 'smStocks/smStockProductInfo'
    }),
    name() {
      return this.$t('stock_management.sales.title')
    },
    config() {
      return {
        permission: permissions.SM_STOCK,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        list_url: '/stock-management/sm-stocks',
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    title() {
      return this.name;
    },
    product_barcode_list() {
      return buildDefaultAutocompleteList(this.products, {
        uuid: 'uuid',
        name: 'barcode',
      });
    },
    product_name_list() {
      return buildDefaultAutocompleteList(this.products);
    },
    measure_unit_list() {
      return buildDefaultDropdownList(Object.assign(this.measureUnits));
    },
    warehouse_list() {
      return buildDefaultDropdownList(Object.assign([], this.warehouses));
    },
    productBarcode() {
      return {
        uuid: this.data.product?.uuid,
        name: this.data.product?.barcode
      }
    },
    productName() {
      return {
        uuid: this.data.product?.uuid,
        name: this.data.product?.name
      }
    },
    partnerName: {
      get() {
        return {
          uuid: this.data.product?.partner?.uuid,
          name: this.data.product?.partner?.name
        }
      },
      set(val) {
        return {
          uuid: val.uuid,
          name: val.name
        }
      }
    },
    total() {
      if (this.data.product.quantity > 0 && this.data.product.unit_price > 0) {
        return (this.data.product.quantity * this.data.product.unit_price).toFixed(2);
      }

      return (0).toFixed(2);
    }
  },
  methods: {
    ...mapActions({
      getProductTypes: 'smProductTypes/searchSmProductTypes',
      get: 'smProducts/getSmProduct',
      create: 'smTransactions/createSalesTransaction',
      getProducts: 'smProducts/getSmProducts',
      getMeasureUnits: 'measureUnits/searchMeasureUnits',
      getWarehouses: 'smWarehouses/getSmWarehouses',
      getPartners: 'smPartners/getSmPartners',
      getStockProductInfo: 'smStocks/getSmStockProductInfo'
    }),
    loadData() {
      let vm = this;
      return this.actions.getData(this, (data) => {
        vm.$set(vm.data, 'product', {
          measure_unit_id: null,
        });
        vm.$set(vm.data, 'warehouse_uuid', null);
      }, (data) => {

      });
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    prepareSave(callback, context, backPath) {
      this.$v.data.$touch();

      this.data.product.partner = this.partnerInfo;
      let partnerValidate = this.$refs['partner'].validate();
      this.data.product.price = this.total;
      let whs = this.warehouses.find(w => w.uuid === this.data.warehouse_uuid);

      if(whs) {
        this.data.department_uuid = whs.department_uuid;
      }

      let max = this.getStockCount(this.data.warehouse_uuid);

      if (parseFloat(this.data.product.quantity) <= 0) {
        this.maxQtyError = this.$t('stock_management.errors.quantity_cannot_be_zero')
        return false;
      } else if (parseFloat(this.data.product.quantity) > parseFloat(max)) {
        this.maxQtyError = this.$t('stock_management.errors.quantity_cannot_be_greater', { max: max })
        return false;
      } else {
        this.maxQtyError = '';
      }

      if(!partnerValidate) {
      errorMessage(this, this.$t('stock_management.errors.partner_is_required'));
        return false;
      }

      if(partnerValidate && !this.$v.data.$invalid
      ) {
        if (typeof callback == 'function' ) {
          callback(context, backPath);
        }
      }
    },
    save() {
      this.prepareSave(
        this.actions.save, this, this.config.list_url
      );
    },
    saveAndNew() {
      this.prepareSave(
        this.actions.saveAndNew,this, this.config.list_url+'/sales', { product: {} }
      );
    },
    newObject() {
      let addUrl = '';
      this.$router.push({
        path: addUrl
      }).catch((e)=>{
        window.location.href = addUrl;
      });
    },
    onlyPrice(event) {
      return only_price(event);
    },
    onlyNumbers(event) {
      return only_numbers(event);
    },
    onProductBarcodeSelected(data) {
      if(data && data.value) {
        this.data.product = this.products.find(p => p.uuid === data.value);
        this.productNameSearch = {
            label: this.data.product.name,
            value: this.data.product.uuid
        };
      }
    },
    onProductNameSelected(data) {
      if(data && data.value) {
        this.data.product = this.products.find(p => p.uuid === data.value);
        this.productBarcodeSearch = {
          label: this.data.product.barcode,
          value: this.data.product.uuid
        };
        this.stock_product_info = null;
      }
    },
    onPartnerSelected(data) {
      this.data.product.partner = data;
      this.addNewPartner = !this.data.product.partner.uuid ?? 'edit';
      this.partnerInfo = data;
    },
    onPartnerChanged(data) {
      if (!this.partnerInfo) {
        this.partnerInfo = {};
      }

      if (data && data.name !== this.partnerInfo.name) {
        this.addNewPartner = data.name ? 'add' : '';
        this.partnerInfo.name = data.name;
      }

      if(this.addNewPartner === 'add') {
        this.partnerInfo = { name: data?.name };
      }

      if(!data) {
        this.partnerInfo = null;
      }
    },
    showPartnerDialog(partner) {
      this.showPartnersModal = true;
      this.partnersModalData = partner ?? {};
      this.partnersModalData.action = this.addNewPartner;
    },
    onPartnerDialogClosed(data) {
      this.showPartnersModal = false;

      if (data && data.name) {
        this.addNewPartner = 'edit';
        this.data.product.partner = data;
        this.partnerName = data;
      }
    },
    getStockCount(warehouse_uuid) {
      let stockItem = (this.data.product && this.data.product.stock_list)
        ? this.data.product.stock_list.find(whs => whs.warehouse_uuid === warehouse_uuid) : null;

      if(stockItem) {
        return stockItem.quantity;
      }

      return 0;
    }
  },
  mounted() {
    this.getProductTypes({
      pagination: 0,
    });
    this.loadData();
    this.getMeasureUnits({
      enablePagination: 0,
    });
    this.actions.handleParams(this);
  },
  watch: {
    productTypes: {
      handler: function(newVal) {
        this.product_types = Object.keys(newVal).map((key) => {
          return newVal[key];
        });
      },
      deep: true,
    },
    $route: {
      handler: function(newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    permission: {
      handler: function(newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    },
    'data.price_net': (data) => {

    },
    data: {
      handler: (data) => {

      },
      deep: true,
    },
    'data.warehouse_uuid': {
      handler: function(newVal) {
        this.getProducts({
          warehouse_uuid: newVal,
          limit: 999,
          has_stock: 1,
          enablePagination: 0,
        });
      },
      deep: true,
    },
    'data.product.quantity': {
      handler: function(newVal) {
        if(this.data.product.uuid && this.data.warehouse_uuid && this.data.product.quantity > 0) {
          this.getStockProductInfo({
            product_uuid: this.data.product.uuid,
            warehouse_uuid: this.data.warehouse_uuid,
            quantity: this.data.product.quantity
          });
        }
      }
    },
    stockProductInfo: {
      handler: function(newVal) {
        this.stock_product_info = newVal;
      },
      deep: true,
    }
  },
  validations(){
    return {
      data: {
        product: {
          name: {
            required,
          },
          unit_price: {
            required,
          },
          quantity: {
            required
          },
        },
        warehouse_uuid: {
          required,
        },
      }
    }
  }
}
</script>

<style scoped>
.a-icon {
  position: relative;
  top: -26px;
  left: +40px;
}

.fyi-price {
  font-size: 10px;
}

.mu {
  position: relative;
  left: 0%;
  top: 39px;
}

.font-small {
  font-size: 12px;
}

.float-right {
  float: right;
  position: relative;
  top: -40px;
  left: 30px;
}
</style>

