// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'

//common
export const SET_PAGINATION = "SET_PAGINATION";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";

//company
export const SET_COMPANY_FILTERS = "SET_COMPANY_FILTERS";
export const SET_COMPANIES = "SET_COMPANIES";
export const SET_COMPANY = "SET_COMPANY";
export const SET_COMPANY_ITEM = "SET_COMPANY_ITEM";
export const DELETE_COMPANY_ITEM = "DELETE_COMPANY_ITEM";
export const SET_PARENT_COMPANIES = "SET_PARENT_COMPANIES";
export const SET_DEPARTMENTS = "SET_DEPARTMENTS";
export const SET_DEPARTMENT_LIST = "SET_DEPARTMENT_LIST";
export const SET_DEPARTMENT = "SET_DEPARTMENT";
export const SET_DEPARTMENT_ITEM = "SET_DEPARTMENT_ITEM";
export const DELETE_DEPARTMENT_ITEM = "DELETE_DEPARTMENT_ITEM";
export const SET_PARENT_DEPARTMENTS = "SET_PARENT_DEPARTMENTS";
export const SET_CURRENT_DEPARTMENT = "SET_CURRENT_DEPARTMENT";
export const SET_DEPARTMENT_FILTERS = "SET_DEPARTMENT_FILTERS";

//user
export const SET_USER_FILTERS = "SET_USER_FILTERS";
export const SET_USERS = "SET_USERS";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_ITEM = "SET_USER_ITEM";
export const DELETE_USER_ITEM = "DELETE_USER_ITEM";

//server
export const SET_SERVER_FILTERS = "SET_SERVER_FILTERS";
export const SET_SERVERS = "SET_SERVERS";
export const SET_SERVER = "SET_SERVER";
export const SET_SERVER_ITEM = "SET_SERVER_ITEM";
export const DELETE_SERVER_ITEM = "DELETE_SERVER_ITEM";

export const SET_UNIT_FILTERS = "SET_UNIT_FILTERS";
export const SET_UNITS = "SET_UNITS";
export const SET_UNIT = "SET_UNIT";
export const SET_UNIT_ITEM = "SET_UNIT_ITEM";
export const SET_PARENT_UNITS = "SET_PARENT_UNITS";
export const ADD_UNIT_ITEM = "ADD_UNIT_ITEM";
export const UPDATE_UNIT_ITEM = "UPDATE_UNIT_ITEM";
export const DELETE_UNIT_ITEM = "DELETE_UNIT_ITEM";

//domain
export const SET_DOMAIN = "SET_DOMAIN";

//roles and permissions
export const SET_ROLES = "SET_ROLES";
export const SET_ROLE = "SET_ROLE";
export const ADD_ROLE = "ADD_ROLE";
export const REMOVE_ROLE_ITEM = "REMOVE_ROLE_ITEM";
export const UPDATE_ROLE_ITEM = "UPDATE_ROLE_ITEM";

export const SET_DEFAULT_PERMISSIONS = "SET_DEFAULT_PERMISSIONS";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_PERMISSION = "SET_PERMISSION";
export const REMOVE_PERMISSION_ITEM = "REMOVE_PERMISSION_ITEM";
export const UPDATE_PERMISSION_ITEM = "UPDATE_PERMISSION_ITEM";

//files
export const SET_RECENTLY_UPLOADED_FILES = "SET_RECENTLY_UPLOADED_FILES";
export const SET_RECENTLY_UPLOADED_ZIP_FILES = "SET_RECENTLY_UPLOADED_ZIP_FILES";

//works
export const SET_WORKS = "SET_WORKS";

//season on parcel has works
export const SET_SEASON_ON_PARCEL_HAS_CULTURE = "SET_SEASON_ON_PARCEL_HAS_CULTURE";
export const SET_SPC_WORKS = "SET_SPC_WORKS";
export const SET_SPC_WORK_SUMMARY = "SET_SPC_WORK_SUMMARY";
export const SET_SPC_MACHINES = "SET_SPC_MACHINES";
export const SET_SPC_PARCELS = "SET_SPC_PARCELS";

//work-inputs
export const SET_INPUTS = "SET_INPUTS";
export const SET_INPUT = "SET_INPUT";
export const SET_INPUT_FILTERS = "SET_INPUT_FILTERS";

// fito products
export const SET_FITO_PRODUCTS = "SET_FITO_PRODUCTS";
export const SET_FITO_PRODUCTS_LIGHT = "SET_FITO_PRODUCTS_LIGHT";
export const SET_FITO_MEASURE_UNITS = "SET_FITO_MEASURE_UNITS";

// fertility products
export const SET_FERTILITY_PRODUCTS = "SET_FITO_PRODUCTS";
export const SET_FERTILITY_PRODUCTS_LIGHT = "SET_FITO_PRODUCTS_LIGHT";
export const SET_FERTILITY_MEASURE_UNITS = "SET_FERTILITY_MEASURE_UNITS";

//persons
export const SET_PERSONS = "SET_PERSONS";
export const SET_PERSON_FILTERS = "SET_PERSON_FILTERS";

//work machines
export const SET_WORK_MACHINES = "SET_WORK_MACHINES";
export const SET_WORK_MACHINE = "SET_WORK_MACHINE";
export const SET_WORK_MACHINE_ITEM = "SET_WORK_MACHINE_ITEM";
export const SET_WORK_MACHINE_FILTERS = "SET_WORK_MACHINE_FILTERS";

//cultures
export const SET_CULTURES = "SET_CULTURES";
export const SET_CULTURE = "SET_CULTURE";
export const ADD_CULTURE_ITEM = "ADD_CULTURE_ITEM";
export const UPDATE_CULTURE_ITEM = "UPDATE_CULTURE_ITEM";
export const DELETE_CULTURE_ITEM = "DELETE_CULTURE_ITEM";
export const SET_CULTURE_FILTERS = "SET_CULTURE_FILTERS";

//culture varieties
export const SET_VARIETIES = "SET_VARIETIES";
export const SET_VARIETY = "SET_VARIETY";
export const ADD_VARIETY_ITEM = "ADD_VARIETY_ITEM";
export const UPDATE_VARIETY_ITEM = "UPDATE_VARIETY_ITEM";
export const DELETE_VARIETY_ITEM = "DELETE_VARIETY_ITEM";
export const SET_VARIETY_FILTERS = "SET_VARIETY_FILTERS";

//culture bbchs
export const SET_BBCHS = "SET_BBCHS";
export const SET_BBCH = "SET_BBCH";
export const ADD_BBCH_ITEM = "ADD_BBCH_ITEM";
export const UPDATE_BBCH_ITEM = "UPDATE_BBCH_ITEM";
export const DELETE_BBCH_ITEM = "DELETE_BBCH_ITEM";
export const SET_BBCH_FILTERS = "SET_BBCH_FILTERS";

//parcels
export const SET_PARCELS = "SET_PARCELS";
export const SET_PARCEL = "SET_PARCEL";
export const ADD_PARCEL_ITEM = "ADD_PARCEL_ITEM";
export const UPDATE_PARCEL_ITEM = "UPDATE_PARCEL_ITEM";
export const DELETE_PARCEL_ITEM = "DELETE_PARCEL_ITEM";
export const SET_PARCEL_FILTERS = "SET_PARCEL_FILTERS";

//scouting
export const SET_SCOUTING = "SET_SCOUTING";
export const SET_SCOUTINGS = "SET_SCOUTINGS";
export const SET_SCOUTING_FILTERS = "SET_SCOUTING_FILTERS";

//salary calculator
//sc bonus
export const SET_SC_BONUSES = "SET_SC_BONUSES";
export const SET_SC_BONUS_TYPES = "SET_SC_BONUS_TYPES";
export const SET_SC_BONUS_CALCULATION_TYPES = "SET_SC_BONUS_CALCULATION_TYPES";
export const SET_SC_BONUS = "SET_SC_BONUS";
export const ADD_SC_BONUS_ITEM = "ADD_SC_BONUS_ITEM";
export const UPDATE_SC_BONUS_ITEM = "UPDATE_SC_BONUS_ITEM";
export const DELETE_SC_BONUS_ITEM = "DELETE_SC_BONUS_ITEM";
export const SET_SC_BONUS_FILTERS = "SET_SC_BONUS_FILTERS";

//sc holidays
export const SET_SC_HOLIDAYS = "SET_SC_HOLIDAYS";
export const SET_SC_HOLIDAY = "SET_SC_HOLIDAY";
export const ADD_SC_HOLIDAY_ITEM = "ADD_SC_HOLIDAY_ITEM";
export const UPDATE_SC_HOLIDAY_ITEM = "UPDATE_SC_HOLIDAY_ITEM";
export const DELETE_SC_HOLIDAY_ITEM = "DELETE_SC_HOLIDAY_ITEM";
export const SET_SC_HOLIDAY_FILTERS = "SET_SC_HOLIDAY_FILTERS";

//sc employee
export const SET_SC_EMPLOYEES = "SET_SC_EMPLOYEES";
export const SET_SC_EMPLOYEE = "SET_SC_EMPLOYEE";
export const ADD_SC_EMPLOYEE_ITEM = "ADD_SC_BONUS_ITEM";
export const UPDATE_SC_EMPLOYEE_ITEM = "UPDATE_SC_EMPLOYEE_ITEM";
export const DELETE_SC_EMPLOYEE_ITEM = "DELETE_SC_EMPLOYEE_ITEM";
export const SET_SC_EMPLOYEE_FILTERS = "SET_SC_EMPLOYEE_FILTERS";

//sc employee
export const SET_SC_EMPLOYEE_ROLES = "SET_SC_EMPLOYEE_ROLES";
export const SET_SC_EMPLOYEE_ROLE = "SET_SC_EMPLOYEE_ROLE";
export const ADD_SC_EMPLOYEE_ROLE_ITEM = "ADD_SC_EMPLOYEE_ROLE_ITEM";
export const UPDATE_SC_EMPLOYEE_ROLE_ITEM = "UPDATE_SC_EMPLOYEE_ROLE_ITEM";
export const DELETE_SC_EMPLOYEE_ROLE_ITEM = "DELETE_SC_EMPLOYEE_ROLE_ITEM";
export const SET_SC_EMPLOYEE_ROLE_FILTERS = "SET_SC_EMPLOYEE_ROLE_FILTERS";

//sc measure unit
export const SET_SC_MEASURE_UNITS = "SET_SC_MEASURE_UNITS";
export const SET_SC_MEASURE_UNIT = "SET_SC_MEASURE_UNIT";
export const ADD_SC_MEASURE_UNIT_ITEM = "ADD_SC_MEASURE_UNIT_ITEM";
export const UPDATE_SC_MEASURE_UNIT_ITEM = "UPDATE_SC_MEASURE_UNIT_ITEM";
export const DELETE_SC_MEASURE_UNIT_ITEM = "DELETE_SC_MEASURE_UNIT_ITEM";
export const SET_SC_MEASURE_UNIT_FILTERS = "SET_SC_MEASURE_UNIT_FILTERS";

//sc shift
export const SET_SC_SHIFTS = "SET_SC_SHIFTS";
export const SET_SC_SHIFT = "SET_SC_SHIFT";
export const ADD_SC_SHIFT_ITEM = "ADD_SC_SHIFT_ITEM";
export const UPDATE_SC_SHIFT_ITEM = "UPDATE_SC_SHIFT_ITEM";
export const DELETE_SC_SHIFT_ITEM = "DELETE_SC_SHIFT_ITEM";
export const SET_SC_SHIFT_FILTERS = "SET_SC_SHIFT_FILTERS";

//sc work
export const SET_SC_WORKS = "SET_SC_WORKS";
export const SET_SC_WORK = "SET_SC_WORK";
export const SET_SC_WORK_COLLECTOR = "SET_SC_WORK_COLLECTOR";
export const ADD_SC_WORK_ITEM = "ADD_SC_WORK_ITEM";
export const UPDATE_SC_WORK_ITEM = "UPDATE_SC_WORK_ITEM";
export const DELETE_SC_WORK_ITEM = "DELETE_SC_WORK_ITEM";
export const SET_SC_WORK_FILTERS = "SET_SC_WORK_FILTERS";

//sc work group
export const SET_SC_WORK_GROUPS = "SET_SC_WORK_GROUPS";
export const SET_SC_WORK_GROUP = "SET_SC_WORK_GROUP";
export const ADD_SC_WORK_GROUP_ITEM = "ADD_SC_WORK_GROUP_ITEM";
export const UPDATE_SC_WORK_GROUP_ITEM = "UPDATE_SC_WORK_GROUP_ITEM";
export const DELETE_SC_WORK_GROUP_ITEM = "DELETE_SC_WORK_GROUP_ITEM";
export const SET_SC_WORK_GROUP_FILTERS = "SET_SC_WORK_GROUP_FILTERS";

//sc work pivot
export const SET_SC_WORK_PIVOTS = "SET_SC_WORK_PIVOTS";
export const SET_SC_WORK_COLLECTOR_HECTARE_DATA = "SET_SC_WORK_COLLECTOR_HECTARE_DATA";
export const SET_SC_WORK_COLLECTOR_COLUMN_SETTINGS = "SET_SC_WORK_COLLECTOR_COLUMN_SETTINGS";
export const SET_SC_WORK_HIERARCHY = "SET_SC_WORK_HIERARCHY";
export const SET_SC_WORK_HIERARCHY_FILTERS = "SET_SC_WORK_HIERARCHY_FILTERS";
export const SET_SC_WORK_PIVOT = "SET_SC_WORK_PIVOT";
export const ADD_SC_WORK_PIVOT_ITEM = "ADD_SC_WORK_PIVOT_ITEM";
export const UPDATE_SC_WORK_PIVOT_ITEM = "UPDATE_SC_WORK_PIVOT_ITEM";
export const DELETE_SC_WORK_PIVOT_ITEM = "DELETE_SC_WORK_PIVOT_ITEM";
export const SET_SC_WORK_PIVOT_FILTERS = "SET_SC_WORK_PIVOT_FILTERS";
export const SET_SC_DAILY_GRID_COLUMNS = "SET_SC_DAILY_GRID_COLUMNS";

//sc work log pivot
export const SET_SC_WORK_LOGS = "SET_SC_WORK_LOGS";
export const SET_SC_WORK_LOG = "SET_SC_WORK_LOG";
export const ADD_SC_WORK_LOG_ITEM = "ADD_SC_WORK_LOG_ITEM";
export const UPDATE_SC_WORK_LOG_ITEM = "UPDATE_SC_WORK_LOG_ITEM";
export const DELETE_SC_WORK_LOG_ITEM = "DELETE_SC_WORK_LOG_ITEM";
export const SET_SC_WORK_LOG_FILTERS = "SET_SC_WORK_LOG_FILTERS";

//custom products
export const ADD_CUSTOM_PRODUCT = "ADD_CUSTOM_PRODUCT";
export const SET_CUSTOM_PRODUCTS = "SET_CUSTOM_PRODUCTS";

//custom product statuses
export const SET_CUSTOM_PRODUCT_STATUSES = "SET_CUSTOM_PRODUCT_STATUSES";

//seasons
export const SET_SEASON = "SET_SEASON";
export const SET_SEASONS = "SET_SEASONS";
export const SET_SEASON_FILTERS = "SET_SEASON_FILTERS";

export const SET_LAYERS = "SET_LAYERS";
export const SET_LAYER_WORKS = "SET_LAYER_WORKS";
export const SET_LAYER_FILTERS = "SET_LAYER_FILTERS";
export const SET_LAYER_SEASON = "SET_LAYER_SEASON";
export const SET_LAYERS_LOADING = "SET_LAYERS_LOADING";

//import config
export const SET_IMPORT_TEMPLATE = "SET_IMPORT_TEMPLATE";
export const SET_IMPORT_TEMPLATES = "SET_IMPORT_TEMPLATES";
export const SET_REQUIRED_ATTRIBUTES = "SET_REQUIRED_ATTRIBUTES";
export const SET_IMPORT_CONFIGURATIONS = "SET_IMPORT_CONFIGURATIONS";
export const UPDATE_IMPORT_CONFIGURATIONS = "UPDATE_IMPORT_CONFIGURATIONS";
export const SET_IMPORT_SOURCE_CREDENTIALS = "SET_IMPORT_SOURCE_CREDENTIALS";
export const UPDATE_IMPORT_VALIDATED_DATA = "UPDATE_IMPORT_VALIDATED_DATA";
export const SET_IMPORT_IMPORT_VALIDATED_DATA = "SET_IMPORT_IMPORT_VALIDATED_DATA";
//export const VALIDATED_DATA_TRANSFER_TO_LIVE_DATA = "VALIDATED_DATA_TRANSFER_TO_LIVE_DATA";

//settings
export const SET_SETTING_GROUPS = "SET_SETTING_GROUPS";
export const SET_GROUP_SETTINGS = "SET_GROUP_SETTINGS";
export const SET_GROUP_SETTING = "SET_GROUP_SETTING";
export const SET_CUSTOM_SETTING_VALUES = "SET_CUSTOM_SETTING_VALUES";

//stock management
//partners
export const SET_SM_PARTNERS = "SET_SM_PARTNERS";
export const SET_SM_PARTNER = "SET_SM_PARTNER";
export const ADD_SM_PARTNER_ITEM = "ADD_SM_PARTNER_ITEM";
export const UPDATE_SM_PARTNER_ITEM = "UPDATE_SM_PARTNER_ITEM";
export const DELETE_SM_PARTNER_ITEM = "DELETE_SM_PARTNER_ITEM";
export const SET_SM_PARTNER_FILTERS = "SET_SM_PARTNER_FILTERS";
export const SET_SM_PARTNER_TYPES = "SET_SM_PARTNER_TYPES";

//warehouses
export const SET_SM_WAREHOUSES = "SET_SM_WAREHOUSES";
export const SET_SM_WAREHOUSE = "SET_SM_WAREHOUSE";
export const ADD_SM_WAREHOUSE_ITEM = "ADD_SM_WAREHOUSE_ITEM";
export const UPDATE_SM_WAREHOUSE_ITEM = "UPDATE_SM_WAREHOUSE_ITEM";
export const DELETE_SM_WAREHOUSE_ITEM = "DELETE_SM_WAREHOUSE_ITEM";
export const SET_SM_WAREHOUSE_FILTERS = "SET_SM_WAREHOUSE_FILTERS";

//products
export const SET_SM_PRODUCTS = "SET_SM_PRODUCTS";
export const SET_SM_PRODUCT = "SET_SM_PRODUCT";
export const ADD_SM_PRODUCT_ITEM = "ADD_SM_PRODUCT_ITEM";
export const UPDATE_SM_PRODUCT_ITEM = "UPDATE_SM_PRODUCT_ITEM";
export const DELETE_SM_PRODUCT_ITEM = "DELETE_SM_PRODUCT_ITEM";
export const SET_SM_PRODUCT_FILTERS = "SET_SM_PRODUCT_FILTERS";

//product types
export const SET_SM_PRODUCT_TYPES = "SET_SM_PRODUCT_TYPES";
export const SET_SM_PRODUCT_TYPE = "SET_SM_PRODUCT_TYPE";
export const ADD_SM_PRODUCT_TYPE_ITEM = "ADD_SM_PRODUCT_TYPE_ITEM";
export const UPDATE_SM_PRODUCT_TYPE_ITEM = "UPDATE_SM_PRODUCT_TYPE_ITEM";
export const DELETE_SM_PRODUCT_TYPE_ITEM = "DELETE_SM_PRODUCT_TYPE_ITEM";
export const SET_SM_PRODUCT_TYPE_FILTERS = "SET_SM_PRODUCT_TYPE_FILTERS";

//stock
export const SET_SM_STOCKS = "SET_SM_STOCKS";
export const SET_SM_STOCK_PRODUCT_INFO = "SET_SM_STOCK_PRODUCT_INFO";
export const SET_SM_STOCK = "SET_SM_STOCK";
export const ADD_SM_STOCK_ITEM = "ADD_SM_STOCK_ITEM";
export const UPDATE_SM_STOCK_ITEM = "UPDATE_SM_STOCK_ITEM";
export const DELETE_SM_STOCK_ITEM = "DELETE_SM_STOCK_ITEM";
export const SET_SM_STOCK_FILTERS = "SET_SM_STOCK_FILTERS";

//meeasure units
//partners
export const SET_MEASURE_UNITS = "SET_MEASURE_UNITS";
export const SET_MEASURE_UNIT = "SET_MEASURE_UNIT";
export const SET_MEASURE_UNIT_FILTERS = "SET_MEASURE_UNIT_FILTERS";

//transactions
export const SET_SM_RECEPTION_TRANSACTION = "SET_SM_RECEPTION_TRANSACTION";
export const SET_SM_SALES_TRANSACTION = "SET_SM_SALES_TRANSACTION";
export const SET_SM_CONSUM_TRANSACTION = "SET_SM_CONSUM_TRANSACTION";
export const SET_SM_TRANSFER_TRANSACTION = "SET_SM_TRANSFER_TRANSACTION";
export const SET_SM_TRANSACTIONS = "SET_SM_TRANSACTIONS";
export const SET_SM_STOCK_MOVEMENTS = "SET_SM_STOCK_MOVEMENTS";
export const SET_SM_STOCK_MOVEMENT_FILTERS = "SET_SM_STOCK_MOVEMENT_FILTERS";
export const SET_SM_TRANSACTION_FILTERS = "SET_SM_TRANSACTION_FILTERS";
export const SET_SM_TRANSACTION_TYPES = "SET_SM_TRANSACTION_TYPES";
export const SET_SM_STOCK_MOVEMENT_TYPES = "SET_SM_STOCK_MOVEMENT_TYPES";

//justifying documents - document blocks
export const SET_DOCUMENT_BLOCKS = "SET_DOCUMENT_BLOCKS";
export const SET_DOCUMENT_BLOCK = "SET_DOCUMENT_BLOCK";
export const SET_DOCUMENT_TYPES = "SET_DOCUMENT_TYPES";
export const ADD_DOCUMENT_BLOCK_ITEM = "ADD_DOCUMENT_BLOCK_ITEM";
export const UPDATE_DOCUMENT_BLOCK_ITEM = "UPDATE_DOCUMENT_BLOCK_ITEM";
export const DELETE_DOCUMENT_BLOCK_ITEM = "DELETE_DOCUMENT_BLOCK_ITEM";
export const SET_DOCUMENT_BLOCK_FILTERS = "SET_DOCUMENT_BLOCK_FILTERS";

//set agr filters
export const SET_DEPARTMENT_TYPES = "SET_DEPARTMENT_TYPES";
export const SET_DEPARTMENT_FILTER_TYPES = "SET_DEPARTMENT_FILTER_TYPES";

