<template>
  <card :title="title" id="details" v-if="canEdit || canDelete" v-cloak>
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg"  :title="title" @click="newObject()"/>
    </span>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-3 text-left">
        <label class="control-label">{{ $t('stock_management.products.name') }}</label>
        <autocomplete-search
          :placeholder="$t('stock_management.products.search_by_name')"
          :dispatch="getProducts"
          search-key="name"
          ref="productName"
          :required="true"
          :data="productName"
          @onSelected="onProductNameSelected"
          @onChange="onProductNameChanged"
        ></autocomplete-search>
      </div>
      <div class="form-group col-sm-12 col-lg-3 text-left">
        <label class="control-label">{{ $t('stock_management.products.barcode') }}</label>
        <autocomplete-search
          :placeholder="$t('stock_management.products.search_by_barcode')"
          :dispatch="getProducts"
          search-key="barcode"
          ref="productBarcode"
          :required="true"
          :data="productBarcode"
          @onSelected="onProductBarcodeSelected"
          @onChange="onProductBarcodeChanged"
        ></autocomplete-search>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 col-lg-3 text-left">
        <label class="control-label">{{ $t('stock_management.products.unit_price_net') }}</label>
        <input v-model="data.product.price_net"
               class="form-control upper"
               :class="{ 'field-error' : $v.data.product.price_net.$error }"
               v-on:keydown="onlyPrice"
               placeholder="0.00"/>
      </div>
      <div class="col-sm-12 col-lg-3 form-group">
        <label class="control-label">{{$t("stock_management.products.vat_code")}}</label>
        <b-form-select
          v-model="data.vat_code"
          class="form-control"
          :options="vat_code_list"></b-form-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 col-lg-3 text-left">
        <label class="control-label">{{ $t('stock_management.products.quantity') }}</label>
        <input v-model="data.product.quantity"
               class="form-control upper"
               :class="{ 'field-error' : $v.data.product.quantity.$error }"
               v-on:keydown="onlyNumbers"
               placeholder="0.00"/>
      </div>
      <div class="col-sm-4 col-lg-3 form-group">
        <label class="control-label">{{ $t('stock_management.products.select_mu') }}</label>
        <b-form-select
          v-model="data.product.measure_unit_id"
          :class="{ 'field-error' : $v.data.product.measure_unit_id.$error }"
          :placeholder="$t('stock_management.products.select_mu')"
          class="form-control"
          :options="measure_unit_list"></b-form-select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-lg-6 form-group">
        <label class="control-label">{{ $t("stock_management.products.partner") }}</label>
        <autocomplete-search
          :placeholder="$t('stock_management.partners.search')"
          :dispatch="getPartners"
          search-key="name"
          ref="partner"
          :required="true"
          :data="partnerName"
          @onSelected="onPartnerSelected"
          @onChange="onPartnerChanged"
        ></autocomplete-search>
        <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg float-right text-success c-pointer"
             @click="showPartnerDialog" v-if="addNewPartner === 'add'"/>
        <img src="~@/assets/images/desktop/icons/controls/edit.svg" class="icon-svg float-right text-success c-pointer"
             @click="showPartnerDialog" v-if="addNewPartner === 'edit'"/>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 form-group">
        <label class="control-label">{{$t("stock_management.products.type")}}</label>
        <b-form-select
          v-model="data.product.product_type_uuid"
          class="form-control"
          :options="product_type_list"></b-form-select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-lg-6 form-group">
        <label class="control-label">{{$t("common.warehouse")}}</label>
        <b-form-select
          v-model="data.warehouse_uuid"
          class="form-control"
          :class="{ 'field-error' : $v.data.warehouse_uuid.$error }"
          :options="warehouse_list"></b-form-select>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <label class="control-label font-weight-bold">{{$t("common.total")}}</label>
        <div>{{ total +' '+ $currency.name }}</div>
      </div>
    </div>

    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>

    <CreatePartnerDialog :show="showPartnersModal" @close="showPartnersModal = false"
                       :data="partnersModalData"
                       @onOK="onPartnerDialogClosed"
    ></CreatePartnerDialog>

  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {
  buildDefaultDropdownList, only_numbers, only_price
} from '@/helpers/common'
import required from "vuelidate/src/validators/required";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import * as actions from '@/helpers/generalActions'
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import AutocompleteSearch from "~/components/Common/AutocompleteSearch/Index.vue";
import CreatePartnerDialog from "~/pages/stock-management/sm-stocks/partials/CreatePartnerDialog.vue";

export default {
  name: 'SMProductsDetails',
  components: {
    CreatePartnerDialog,
    AutocompleteSearch,
    Forbidden,
    AgroActionButtons,
    VueBootstrapTypeahead
  },
  data() {
    return {
      data: { product: {} },
      product_types: this.productTypes ? this.productTypes : [],
      vat_code_list: [
        { value: null, text: '-' },
        { value: 5, text: '5%' },
        { value: 9, text: '9%' },
        { value: 19, text: '19%' },
      ],
      productBarcodeList: [],
      productBarcodeSearch: '',
      selectedProductBarcode: null,
      productNameList: [],
      productNameSearch: '',
      selectedProductName: null,
      productList: [],
      addNewPartner: false,
      showPartnersModal: false,
      partnersModalData: {},
      productBarcode: null,
      productName: null,
      partnerName: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
      domain: 'auth/domain',
      products: 'smProducts/smProducts',
      productTypes: 'smProductTypes/smProductTypes',
      measureUnits: 'measureUnits/measureUnits',
      warehouses: 'smWarehouses/smWarehouses',
      partners: 'smPartners/smPartners'
    }),
    name() {
      return this.$t('stock_management.reception.title')
    },
    config() {
      return {
        permission: permissions.SM_STOCK,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: '/stock-management/sm-stocks',
        confirm_delete: true,
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    title() {
      return this.name;
    },
    product_type_list() {
      return buildDefaultDropdownList(Object.assign(this.productTypes));
    },
    measure_unit_list() {
      return buildDefaultDropdownList(Object.assign(this.measureUnits));
    },
    warehouse_list() {
      return buildDefaultDropdownList(Object.assign([], this.warehouses));
    },
    total() {
      if (this.data.product.quantity > 0 && this.data.product?.price_net) {
        return (this.data.product.quantity * this.data.product.price_net).toFixed(2);
      }
      return (0).toFixed(2);
    }
  },
  methods: {
    ...mapActions({
      getProductTypes: 'smProductTypes/searchSmProductTypes',
      get: 'smProducts/getSmProduct',
      create: 'smTransactions/createReceptionTransaction',
      update: 'smStocks/updateSmStock',
      delete: 'smStocks/deleteSmStock',
      getProducts: 'smProducts/getSmProducts',
      getMeasureUnits: 'measureUnits/searchMeasureUnits',
      getWarehouses: 'smWarehouses/getSmWarehouses',
      getPartners: 'smPartners/getSmPartners'
    }),
    loadData() {
      let vm = this;
      return this.actions.getData(this, (data) => {
        vm.$set(vm.data, 'product', {
          product_type_uuid: null,
          vat_code: null,
          measure_unit_id: null,
        });
        vm.$set(vm.data, 'warehouse_uuid', null);
        vm.$set(vm.data, 'vat_code', null);
        vm.$set(vm.data, 'measure_unit_id', null);
      }, (data) => {

      });
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    prepareSave(callback) {
      this.$v.data.$touch();

      let productNameValidate = this.$refs['productName'].validate();
      let partnerValidate = this.$refs['partner'].validate();
      this.data.product.total = this.total;

      let whs = this.warehouses.find(w => w.uuid === this.data.warehouse_uuid);

      if(whs) {
        this.data.department_uuid = whs.department_uuid;
      }

      if(
        productNameValidate
        && partnerValidate
        && !this.$v.data.$invalid
      ) {
        if (typeof callback === Function) {
          callback();
        }
      }
    },
    save() {
      this.prepareSave(
        this.actions.save(this, this.config.list_url)
      )
    },
    saveAndNew() {
      this.prepareSave(
        this.actions.saveAndNew(this, this.config.list_url+'/reception', {
          product: {},
        })
      );
    },
    newObject() {
      return window.location.href = this.config.list_url+'/reception';
    },
    onlyPrice(event) {
      return only_price(event);
    },
    onlyNumbers(event) {
      return only_numbers(event);
    },
    onProductBarcodeSelected(data) {
      this.fillProductData(data);
    },
    onProductBarcodeChanged(data) {
      this.data.product.barcode = data?.name;
      this.data.product.uuid = data?.uuid;
    },
    onProductNameSelected(data) {
      this.fillProductData(data);
    },
    onProductNameChanged(data) {
      this.data.product.name = data?.name;
      this.data.product.uuid = data?.uuid;

      if(!data || !data.uuid) {
        this.$set(this.data.product, 'barcode', null);
        this.$set(this, 'productBarcode', null);
      }
    },
    getProduct(product_uuid) {
        return this.products.find(p => p.uuid === product_uuid);
    },
    fillProductData(product, callback = undefined) {
      if(product && product.uuid) {
        let prod = this.getProduct(product.uuid);
        if(prod) {
          this.data.product = prod;
          this.productName = {
            uuid: product.uuid,
            name: product.name
          };
          this.productBarcode = {
            uuid: product.uuid,
            name: product.barcode
          };
          this.partnerName = prod?.partner;

          if(callback !== undefined) {
            callback(this.data);
          }
        }
      }
    },
    onPartnerSelected(data) {
      this.data.product.partner = data;
      this.addNewPartner = this.data.product.partner.uuid ?? 'edit';
    },
    onPartnerChanged(data) {
      if (!this.data.product.partner) {
          this.data.product.partner = {};
      }

      if(data.name !== this.data.product.partner?.name) {
        this.addNewPartner = 'add';
        this.partnersModalData = data; //init with name
      }
    },
    showPartnerDialog() {
      this.showPartnersModal = true;

      if(this.addNewPartner === 'add') {
      } else {
        if(this.partnersModalData.name !== this.data.product.partner?.name) {
          this.partnersModalData = { name: this.partnersModalData.name }; //if name has changed while editing
        } else {
          this.partnersModalData = this.data.product.partner;
        }
      }

      this.partnersModalData.action = this.addNewPartner;
    },
    onPartnerDialogClosed(data) {
      this.showPartnersModal = false;

      if (data && data.name) {
        this.addNewPartner = 'edit';
        this.data.product.partner = data;
        this.partnerName = data;
      }
    }
  },
  mounted() {
    this.getProductTypes({
      pagination: 0,
    });
    this.loadData();
    this.getMeasureUnits({
      enablePagination: 0,
    });
    this.getWarehouses({
      company_uuid: this.domain.uuid,
      enablePagination: 0,
    });
    this.actions.handleParams(this);
  },
  watch: {
    productTypes: {
      handler: function(newVal) {
        this.product_types = Object.keys(newVal).map((key) => {
          return newVal[key];
        });
      },
      deep: true,
    },
    $route: {
      handler: function(newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    permission: {
      handler: function(newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    },
    data: {
      handler: (data) => {

      },
      deep: true,
    },
  },
  validations(){
    return {
      data: {
        product: {
          name: {
            required,
          },
          price_net: {
            required,
          },
          quantity: {
            required,
          },
          measure_unit_id: {
            required,
          },
        },
        warehouse_uuid: {
          required,
        },
      }
    }
  }
}
</script>

<style scoped>
  .a-icon {
    position: relative;
    top: -26px;
    left: +40px;
  }

  .float-right {
    float: right;
    position: relative;
    top: -40px;
    left: 30px;
  }
</style>
