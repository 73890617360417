<template type="x/template">
  <transition name="modal" id="pay-contract-dialog">
    <div>
        <DxPopup
        id="dx-dialog"
        width="100vw"
        height="100vh"
        :close-on-outside-click="false"
        :show-close-button="true"
        :visible="showPopup"
        :resize-enabled="false"
        :darg-enabled="false"
        @hidden="$emit('hidden')"
        :wrapper-attr="popupAttributes"
        >
             <template #title>
                <div class="pay_contract_header">
                <div class="left_side">
                    <div @click="backButtonEvent" class="back_button">
                    <img src="@/assets/images/icons/arrow-back.svg" />
                    <div class="back_button_text">Inapoi</div>
                    </div>
                </div>
                <div class="right_side">
                    <img class="close_button"
                    src="@/assets/images/icons/dialog-close.svg"
                    @click="buttonCloseEvent"
                    />
                </div>
                </div>
            </template>

            <template #content>
                <div class="pay_contract_content">
                     <DxTabPanel
                        :element-attr="tabPanelAttributes"
                        :data-source="paySteps"
                        :loop="false"
                        :selected-item="currentItem"
                        :selected-index="currentStep"
                        :animation-enabled="true"
                        :swipe-enabled="false"
                        :show-nav-buttons="false"
                        @selection-changed="tabSelectionChanged($event)"
                    >
                        <template #item="{ data: step }">
                            <div>
                                <div class="pay_contract_title_text">
                                    Modalitate de plată<span>{{currentPaymentOption && currentPaymentOption.title ? currentPaymentOption.title : ''}}</span>
                                </div>
                                <div class="pay_contract_sub_title_text">Ai selectat
                                    <span class="sub_title_text">{{titleAttributes.contractCount}}</span> contracte totalizând
                                    <span class="sub_title_text">{{titleAttributes.totalSurface.toFixed(2)}} ha</span> cu
                                    <span class="sub_title_text">{{titleAttributes.parcelCount}}</span> de parcele în valoare de
                                    <span class="sub_title_text">{{titleAttributes.totalValue.toFixed(2)}} ron</span>
                                    <span v-if="currentPaymentOption && currentPaymentOption.code && currentPaymentOption.code == 'P'">
                                        din care urmează să achiți
                                        <span class="subtitle_green">{{total_product_value_selected.toFixed(2)}} ron</span> restul de plată fiind
                                        <span class="subtitle_orange">{{(titleAttributes.totalValue - total_product_value_selected).toFixed(2)}} ron</span>
                                    </span>
                                </div>
                                <div class="pay_contract_sub_title_text"></div>
                                <div class="pay_method_selector" v-if="step == 1">
                                    <div class="pay_method_selector_item" @click="selectPaymentOption(option)" v-for="option in paymentOptions" :key="option.code">{{option.text}}</div>
                                </div>
                                <div v-if="step == 2" class="payment_step_2">
                                     <DxScrollView :elementAttr="scrollInputsAttributes" :use-native="true" v-if="currentPaymentOption.code == 'N'">
                                        <div class="scrollable_payments" >
                                             <div
                                                class="step-item"
                                                @click="detailStepClick('PAYMENT_DATE')"
                                                :class="[{step_control_error: $v.paymentData.date.$error}]"
                                            >
                                                <div class="step-item-title">Data</div>
                                                <div :class="paymentData.date && paymentData.date.length > 0 ? 'step-item-value' : 'step-item-value-placeholder'">
                                                {{paymentDateText}}
                                                <img
                                                    class="main_filter_right_arrow"
                                                    src="@/assets/images/icons/right_arrow_dg.svg"

                                                    />
                                                </div>
                                            </div>
                                             <div class="step-item"
                                                :class="[{step_control_error: $v.paymentData.totalValue.$error}]"
                                            >
                                                <div class="step-item-title">Suma (ron)</div>
                                                <div class="step-item-value">
                                                <DxNumberBox
                                                    :read-only="contracts.length > 1"
                                                    placeholder="Suma (ron)"
                                                    :format="'#0.## ron'"
                                                    v-model="paymentData.totalValue"
                                                    class="p-value-input-left"
                                                    :show-clear-button="false"
                                                    />
                                                </div>
                                            </div>
                                            <div class="step-item">
                                                <div class="step-item-title">Observații</div>
                                                <div class="step-item-value">
                                                    <DxTextArea v-model="paymentData.notes" class="global_dx_number_input" />
                                                </div>
                                            </div>
                                        </div>
                                      </DxScrollView>
                                      <DxScrollView :elementAttr="scrollInputsAttributes" class="products-to-pay-height" :use-native="true" v-if="currentPaymentOption.code == 'P'">
                                        <div class="scrollable_payments" >
                                            <div v-for="(prod, idx) in productsForPayment" :key="prod.uuid"
                                            :class="[
                                                {'rent_payment_prod' : prod.product.name != 'Numerar'},
                                                {'cash_product' : prod.product.name == 'Numerar'},
                                                {'top_border': prod.product.name != 'Numerar' && productsForPayment.length > 1 && idx == 1},
                                                {'side_border' : prod.product.name != 'Numerar' && productsForPayment.length > 1 && idx > 1 && idx < productsForPayment.length - 1},
                                                {'bottom_border' : prod.product.name != 'Numerar' && productsForPayment.length > 1 && idx == productsForPayment.length - 1}
                                                 ]">
                                                <div class="line_1">
                                                    <DxCheckBox  v-model="prod.selected" class="prod_checkbox" @valueChanged="productSelectedForPayment($event, prod, idx)"/>
                                                    <div class="prod_name">{{prod.product.name}}</div>
                                                    <div class="prod_quantity" v-show="prod.selected">
                                                        <DxNumberBox
                                                        v-show="prod.selected"
                                                        :min="0.00"
                                                        :max="prod.maxValue"
                                                        :read-only="!prod.selected"
                                                        :placeholder="prod.placeholder"
                                                        :format="prod.product.name == 'Numerar' ? '#0.## ron' : ('#0.## ' + prod.measure_unit.name)"
                                                        v-model="prod.value"
                                                        class="p-value-input"
                                                        :show-clear-button="true"
                                                        @value-changed="productQuantityChanged($event, prod)"
                                                        />
                                                        <div v-show="prod.selected && prod.product.name != 'Numerar'" class="prod_value">{{(prod.value * prod.price).toFixed(2)}} ron</div>
                                                    </div>
                                                </div>
                                                <div v-show="prod.selected && prod.product.name != 'Numerar'" class="line_2">Cantitate max.: {{prod.maxValue.toFixed(2)}} {{ prod.measure_unit.name }}</div>
                                                <div v-show="prod.product.name != 'Numerar' && idx > 0 && idx < productsForPayment.length - 1" class="bottom_line"></div>
                                            </div>
                                            <div class="product-bottom-spacer"></div>
                                        </div>
                                     </DxScrollView>
                                     <div v-if="currentPaymentOption.code == 'N'" class="continue_manual_work_data_sticky_dialog">
                                        <b-button
                                        class="continue_button second_action_button"
                                        variant="outline-secondary"
                                        size="sm"
                                        pill
                                        @click="savePayment(false)"
                                        >Plătește</b-button
                                        >
                                    </div>
                                    <div v-if="currentPaymentOption.code == 'P'" class="continue_manual_work_data_sticky_dialog">
                                        <b-button
                                        class="continue_button second_action_button"
                                        variant="outline-secondary"
                                        size="sm"
                                        pill
                                        @click="continueProductPayment"
                                        >Continuă</b-button
                                        >
                                    </div>
                                </div>

                                <div v-if="step == 3">
                                    <div v-if="detail_step_code == 'PAYMENT_DATE' && currentPaymentOption.code == 'N'" class="detail-step">
                                        <functional-calendar
                                        :date-format="'yyyy-mm-dd'"
                                        :is-date-range="false"
                                        :is-date-picker="true"
                                        v-model="calendar"
                                        :change-month-function="true"
                                        :change-year-function="true"
                                        ></functional-calendar>
                                    </div>
                                    <div v-if="currentPaymentOption.code == 'P'">
                                        <DxScrollView :elementAttr="scrollInputsAttributes" :use-native="true">
                                            <div class="scrollable_payments" >
                                                 <div
                                                    class="step-item"
                                                    @click="detailStepClick('PAYMENT_DATE')"
                                                    :class="[{step_control_error: $v.paymentData.date.$error}]"
                                                >
                                                    <div class="step-item-title">Data</div>
                                                    <div :class="paymentData.date && paymentData.date.length > 0 ? 'step-item-value' : 'step-item-value-placeholder'">
                                                    {{paymentDateText}}
                                                    <img
                                                        class="main_filter_right_arrow"
                                                        src="@/assets/images/icons/right_arrow_dg.svg"

                                                        />
                                                    </div>
                                                </div>
                                                <div class="step-item">
                                                    <div class="step-item-title">Produse</div>
                                                    <div class="step-item-value">
                                                        {{productsForPayment.filter(p => p.selected).map(p => p.product.name).join(', ')}}
                                                    </div>
                                                </div>
                                                <div class="step-item">
                                                    <div class="step-item-title">Observații</div>
                                                    <div class="step-item-value">
                                                        <DxTextArea v-model="paymentData.notes" class="global_dx_number_input" />
                                                    </div>
                                                </div>
                                            </div>
                                        </DxScrollView>
                                    </div>
                                     <div v-if="currentPaymentOption.code == 'P'" class="continue_manual_work_data_sticky_dialog">
                                        <b-button
                                        class="continue_button second_action_button"
                                        variant="outline-secondary"
                                        size="sm"
                                        pill
                                        @click="savePayment(true)"
                                        >Plătește</b-button
                                        >
                                    </div>
                                </div>

                                <div v-if="step == 4">
                                    <div v-if="detail_step_code == 'PAYMENT_DATE' && currentPaymentOption.code == 'P'" class="detail-step">
                                        <functional-calendar
                                        :date-format="'yyyy-mm-dd'"
                                        :is-date-range="false"
                                        :is-date-picker="true"
                                        v-model="calendar"
                                        :change-month-function="true"
                                        :change-year-function="true"
                                        ></functional-calendar>
                                    </div>
                                </div>
                            </div>
                        </template>
                     </DxTabPanel>
                </div>
            </template>
        </DxPopup>
        <toast-message ref="toastMessage"></toast-message>
    </div>
  </transition>
</template>


<script>

import { DxPopup, DxPosition } from "devextreme-vue/popup";
import DxTabPanel from "devextreme-vue/tab-panel";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { FunctionalCalendar } from "vue-functional-calendar";
import { required, helpers } from "vuelidate/src/validators";
import DxNumberBox from "devextreme-vue/number-box";
import DxTextArea from 'devextreme-vue/text-area';
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import ToastMessage from "@/components/Mobile/toastMessage.vue"
import { DxCheckBox } from 'devextreme-vue/check-box';

const greatherThenZero = (param) => (value) => {
    return helpers.req(value) && value > 0 && Number(value.toFixed(2))  <=  Number(param.toFixed(2));
};

export default {
    name: "PayContract",
    props: {
        showPopup: {
          type: Boolean,
          default: false,
        },
        contracts: {
            type: Array,
            default() {
                return []
            }
        },
        payments:{
            type: Array,
            default() {
                return []
            }
        },
        editMode: {
          type: Boolean,
          default: false,
        },
    },
    data(){
        return {
            popupAttributes: {
                 class: 'pay_contract_dialog_wrapper'
            },
            tabPanelAttributes: {
                class: 'pay_contract_tabs'
            },
            scrollInputsAttributes: {
                class: 'scroll_input_attribute'
            },
            paySteps: [1,2,3,4],
            currentStep: 0,
            currentItem: 1,
            currentPaymentOption: {},
            paymentOptions: [
                {
                    text:'Numerar',
                    code:'N',
                    title: ' numerar'
                },
                 {
                    text:'Numerar și produs',
                    code:'P',
                    title: ' numerar și produs'
                },

            ],
            titleAttributes:{
                contractCount: 0,
                totalSurface: 0.00,
                parcelCount: 0,
                totalValue: 0.00
            },
            paymentData:{
                date:'',
                totalValue: 0.00,
                notes: ''
            },
            productPaymentData:{

            },
            detail_step_code: '',
            calendar: {},
            productsForPayment: [],
            total_product_value_selected: 0.00,
        };
    },
    validations(){
        return {
            paymentData: {
                date: {
                    required,
                },
                totalValue: {
                    greatherThenZero: greatherThenZero(this.titleAttributes.totalValue),
                }
            }
        }
    },
    components: {
        DxPopup, DxPosition, DxTabPanel, DxScrollView,
        FunctionalCalendar, DxNumberBox,
        DxTextArea,
        ToastMessage,
        DxCheckBox
    },
    watch: {
        showPopup(newVal){
            if(newVal == true){
                this.initTitleAttributes();
                this.resetForm();
                if(this.editMode == true){
                    this.currentPaymentOption = {
                        text:'Numerar și produs',
                        code:'P',
                        title: ' numerar și produs'
                    },
                    this.currentStep = 1;
                    this.currentItem = 2;
                }
            }
        },
        calendar: {
            handler(newVal) {
                if(newVal?.selectedDate){
                    this.paymentData.date = moment(newVal.selectedDate).format("YYYY-MM-DD");
                }
            },
            deep: true,
        },
    },
    computed: {
        paymentDateText(){
            if (this.paymentData?.date) {
                return this.paymentData.date;
            }
            return "Selectează data!";
        },
    },
    methods: {
         ...mapActions({
            createPayment: "leasecontracts/createPayment",
            cashPayment: "leasecontracts/cashPayment",
            editPayment: "leasecontracts/editPayment",
            productPayment: "leasecontracts/productPayment",
            getProducts: "leasecontracts/getProducts",
            getProductPrices: "leasecontracts/getProductPrices",
        }),
         backButtonEvent(){
            if(this.currentItem == 1){
            this.$emit("hidden");
          }
          else{
            this.currentStep = this.currentStep - 1;
          }
        },
        buttonCloseEvent(){
          this.$emit("hidden");
        },
        tabSelectionChanged(e) {
          this.currentItem = e.addedItems[0];
          this.currentStep = this.paySteps.indexOf(this.currentItem);
        },
        selectPaymentOption(option){
            if(option.code == "P" && this.titleAttributes.contractCount > 1){
                this.$refs.toastMessage.showInfo("Plata cu produs este posibil numai pentru un contract de odată!");
            }
            else{
                this.currentPaymentOption = option;
                this.currentStep = this.currentStep + 1;
            }
            this.resetForm(false);
            this.loadProductsForPayment();
        },
        resetForm(resetSteps = true){
            let now = new Date();
            this.paymentData.date = moment(now).format("YYYY-M-D");
            this.paymentData.totalValue = parseFloat(this.titleAttributes.totalValue.toFixed(2));
            this.paymentData.notes = "";
            if(resetSteps){
                this. currentStep = 0;
                this.currentItem = 1;
                this.currentPaymentOption = {};
            }

            this.total_product_value_selected = 0;
        },
        initTitleAttributes(){
            this.titleAttributes = {
                contractCount: 0,
                totalSurface: 0.00,
                parcelCount: 0,
                totalValue: 0
            };

            if(this.contracts && this.contracts.length > 0 && this.editMode == false){
                this.titleAttributes.contractCount = this.contracts.length;
                this.contracts.forEach(c => {
                    this.titleAttributes.parcelCount = this.titleAttributes.parcelCount + c.total_parcel;
                    this.titleAttributes.totalSurface = parseFloat(this.titleAttributes.totalSurface) + parseFloat(c.total_surface);
                    c.years.filter(y => y.selected).forEach(y => {
                        this.titleAttributes.totalValue = parseFloat(this.titleAttributes.totalValue) + parseFloat(y.debt);
                    });

                });
            }

            if(this.contracts && this.contracts.length > 0 && this.editMode == true){
                this.titleAttributes.contractCount = this.contracts.length;
                this.contracts.forEach(c => {
                    this.titleAttributes.parcelCount = this.titleAttributes.parcelCount + c.total_parcel;
                    this.titleAttributes.totalSurface = parseFloat(this.titleAttributes.totalSurface) + parseFloat(c.total_surface);

                    if(this.payments && this.payments.length > 0){
                        c.years.filter(y => this.payments.filter(p => p.contract_owner_balance_uuid == y.uuid).length > 0 ).forEach(y => {
                            this.titleAttributes.totalValue = parseFloat(this.titleAttributes.totalValue) + parseFloat(y.debt);
                        });
                    }

                });

                if(this.payments && this.payments.length > 0){
                    this.payments.forEach(p => {
                        this.titleAttributes.totalValue += p.paid_value;
                    });
                    
                }
            }

            this.loadProductsForPayment();
        },
        savePayment(saveWithProducts = false){
            if(this.editMode){
                this.editPaymentAction();
            }
            else{
                this.createNewPayments(saveWithProducts);
            }
        },
        createNewPayments(saveWithProducts = false){
            if(saveWithProducts){
                this.paymentData.totalValue = this.total_product_value_selected;
            }
            else{
                this.paymentData.totalValue = this.titleAttributes.totalValue;
            }
            this.$v.paymentData.$touch();
            if (!this.$v.paymentData.$invalid) {

                let balances = [];
                this.contracts.forEach(c => {
                    c.years.forEach(y => {
                        balances.push({uuid: y.uuid});
                    });
                });

                let products = [];
                let total_value = 0;

                if(saveWithProducts){
                    this.productsForPayment.forEach(p => {
                        if(p.selected){
                            products.push({
                                uuid: p.product.uuid,
                                quantity: p.value,
                            });
                            total_value += p.value * p.price;
                        }
                    });
                }
                else{
                    total_value = this.paymentData.totalValue;
                    let numerarProduct = this.productsForPayment.find(p => p.product.name == 'Numerar');
                    products.push({
                        uuid: numerarProduct.product.uuid,
                        quantity: total_value,
                    });
                }

                this.createPayment({
                    balances: balances,
                    products: products, 
                    total_value: total_value,
                    payment_date: this.paymentData.date,
                    notes: this.paymentData.notes
                }).then(result => {
                    this.$emit("hidden");
                    this.$emit("reloadContractPayments");
                    this.$refs.toastMessage.showSuccess('Plata a fost înregistrată cu succes!');
                })
                .catch((e) => {
                    this.$refs.toastMessage.showError("A apărut o erroare la plată!");
                    console.error('Payment error: ', e);
                });
            }
            else{
                this.$refs.toastMessage.showError(this.createErrorMessages());
            }
        },
        createPaymentAction(saveWithProducts = false){
            this.$v.paymentData.$touch();
            if (!this.$v.paymentData.$invalid) {
                let multipleOwner = this.contracts.length > 1;
                let contractsToPay = {
                    area_owners: [],
                    payment_date: this.paymentData.date,
                };
                this.contracts.forEach(c => {
                    let totalYearsPayment = 0.00
                    let ownerData = {
                       uuid: c.area_owner.uuid,
                       batch_uuid: "",
                       lease_contract_uuid: c.area_owner.lease_contract.lease_contract_uuid,
                       paid_value: this.paymentData.totalValue,
                       payment_method: 'cash',
                       notes: this.paymentData.notes,
                       years: {}
                    }
                    c.years.forEach(y => {
                        let yearData = {
                            uuid: y.uuid,
                            debt: y.debt,
                            notes: this.paymentData.notes
                        };
                        totalYearsPayment = totalYearsPayment + y.debt;
                        ownerData.years[y.year] = yearData;
                    });
                    if(multipleOwner){
                        ownerData.paid_value = totalYearsPayment;
                    }
                    contractsToPay.area_owners.push(ownerData);
                });
                this.cashPayment(contractsToPay).then(response => {
                    if(saveWithProducts){
                        this.savePaymentWithProduct();
                    }
                    else{
                        this.$emit("hidden");
                        this.$emit("reloadContractPayments");
                        this.$refs.toastMessage.showSuccess('Plata a fost înregistrată cu succes!');
                    }
                })
                .catch((e) => {
                    this.$refs.toastMessage.showError("A apărut o erroare la plata cu numerar!");
                    console.error('Cash payment error: ', e);
                });
            }
            else{
                this.$refs.toastMessage.showError(this.createErrorMessages());
            }
        },
        editPaymentAction(){
            this.$v.paymentData.$touch();
            if (!this.$v.paymentData.$invalid) {
                let paymentsForEdit = [];
                let productsForEdit = [];

                this.payments.forEach(p => {
                    paymentsForEdit.push({uuid: p.uuid});
                })

                let total_value = 0;

                this.productsForPayment.forEach(p => {
                    if(p.selected){
                        productsForEdit.push({
                            uuid: p.product.uuid,
                            quantity: p.value,
                        });
                        total_value += p.value * p.price;
                    }
                });

                this.editPayment({
                    payments: paymentsForEdit,
                    products: productsForEdit,
                    total_value: total_value,
                    payment_date: this.paymentData.date,
                    notes: this.paymentData.notes
                }).then(response => {
                    this.$emit("hidden");
                    this.$emit("reloadContractPayments");
                    this.$refs.toastMessage.showSuccess('Plata a fost înregistrată cu succes!');
                })
                .catch((e) => {
                    this.$refs.toastMessage.showError("A apărut o erroare la plata cu numerar!");
                    console.error('Cash payment error: ', e);
                });
            }
            else{
                this.$refs.toastMessage.showError(this.createErrorMessages());
            }
        },
        editPaymentActionOld(){
            this.$v.paymentData.$touch();
            if (!this.$v.paymentData.$invalid) {
                
                let paymentsToEdit = {
                    area_owner_uuid: this.payments[0].area_owner_uuid,
                    lease_contract_uuid: this.payments[0].lease_contract_uuid,
                    cash_payment_note: "",
                    product_payment_note: "",
                    cash_payment_date: this.paymentData.date,
                    product_payment_date: this.paymentData.date,
                    calculated_total_value: this.total_product_value_selected,
                    new_payment_year_uuid: this.payments[0].payment_details[0].year_uuid,
                    payment_uuids:[],
                    products: []
                };
                this.payments.forEach(p => {
                    paymentsToEdit.payment_uuids.push(p.uuid);
                });
                this.productsForPayment.forEach(prod => {
                    if(prod.selected && prod.product.name != 'Numerar'){
                        paymentsToEdit.products.push({
                            uuid: prod.uuid,
                            name: prod.name,
                            quantity: prod.value
                        });
                    }
                });
                this.editPayment(paymentsToEdit).then(response => {
                    this.$emit("hidden");
                    this.$emit("reloadContractPayments");
                    this.$refs.toastMessage.showSuccess('Plata a fost înregistrată cu succes!');
                })
                .catch((e) => {
                    this.$refs.toastMessage.showError("A apărut o erroare la plata cu numerar!");
                    console.error('Cash payment error: ', e);
                });
            }
            else{
                this.$refs.toastMessage.showError(this.createErrorMessages());
            }
        },
        createErrorMessages(){
            let errorMessages = [];
            if(this.$v.paymentData.date.$invalid){
                errorMessages.push("Data plății este obligatorie!");
            }
            if(this.$v.paymentData.totalValue.$invalid){
                if(this.paymentData.totalValue <= 0){
                    errorMessages.push("Suma este obligatorie!");
                }
                else{
                    errorMessages.push("Suma nu poate fi mai mare decât " + this.titleAttributes.totalValue + " ron!")
                }
            }

            return errorMessages;
        },
        detailStepClick(step_code){
            this.detail_step_code = step_code;
            this.currentStep = this.currentStep + 1;
        },
        loadProductsForPayment(){
            let currentYear = new Date().getFullYear();
            this.getProductPrices({year: currentYear}).then(response => {
                this.productsForPayment = [];
                let cashProd = {
                    product_uuid: '',
                    maxValue: this.titleAttributes.totalValue,
                    name: 'Numerar',
                    placeholder: this.titleAttributes.totalValue.toFixed(2) + " ron",
                    price: 1,
                    selected: false,
                    uuid: 'cash',
                    value: null,
                    year: currentYear
                };
                //this.productsForPayment.push(cashProd);
                response.data.forEach((p, idx) => {
                    if(p.product.name == 'Numerar'){
                        p.rank = -1;
                    }
                    else{
                        p.rank = idx;
                    }
                    p.selected = false;
                    p.value = null;
                    p.maxValue = this.titleAttributes.totalValue/p.price;
                    p.placeholder = p.maxValue.toFixed(2) + " " + p.measure_unit.name;
                    let checkProd = this.productsForPayment.find(obj => obj.product_uuid == p.product.uuid);
                    if(checkProd == null || checkProd == undefined){
                        this.productsForPayment.push(p);
                    }
                    // else if(checkProd.year < p.year){
                    //     let checkIndex = this.productsForPayment.findIndex(obj => obj.name == p.name);
                    //     this.productsForPayment[checkIndex] = p;
                    // }

                    this.productsForPayment.sort(p => p.rank);
                });
            });
        },
        productSelectedForPayment(option, prod, idx){
            if(prod.selected == false){
                prod.value = null;
            }
            this.$set(this.productsForPayment, idx, prod);
        },
        productQuantityChanged(option, prod){
            let valueDifference = (option.value ? option.value : 0) - (option.previousValue ? option.previousValue : 0);
            this.recalculateAvailableQuantities(valueDifference * prod.price);

        },
        recalculateAvailableQuantities(valueDifference){
            this.total_product_value_selected = this.total_product_value_selected + valueDifference;
            this.productsForPayment.forEach(prod => {
                if(prod.selected){
                    prod.maxValue = ((this.titleAttributes.totalValue - this.total_product_value_selected )/prod.price) + (prod.value ? prod.value : 0);
                    prod.placeholder = prod.maxValue.toFixed(2) + (prod.uuid == 'cash' ? " ron" : " kg");
                }
                else{
                    prod.maxValue = (this.titleAttributes.totalValue - this.total_product_value_selected)/prod.price;
                    prod.placeholder = prod.maxValue.toFixed(2) + (prod.uuid == 'cash' ? " ron" : " kg");
                }

            });
        },
        continueProductPayment(){
            if(this.total_product_value_selected > 0){
                this.currentStep = this.currentStep + 1;
            }
            else{
                this.$refs.toastMessage.showError("Trebuie selectat cel puțin un produs sau numerar!");
            }
        },
        savePaymentWithProduct(){
            let cashProduct = this.productsForPayment.find(obj => obj.uuid == 'cash');
            let onlyProducts = this.productsForPayment.filter(obj => obj.uuid != 'cash' && obj.selected && obj.value && obj.value > 0 );
            if(onlyProducts && onlyProducts.length > 0){
                let contractToPayWithProduct = {
                    area_owner_uuid: this.contracts[0].area_owner.uuid,
                    batch_uuid: "",
                    lease_contract_uuid: this.contracts[0].area_owner.lease_contract.lease_contract_uuid,
                    payment_method: "product",
                    notes: this.paymentData.notes,
                    payment_date: this.paymentData.date,
                    calculated_total_value: this.total_product_value_selected - (cashProduct ? (cashProduct.value ? cashProduct.value : 0) : 0),
                    years: {},
                    products: []
                };
                this.contracts[0].years.forEach(year => {
                    if(year.debt > 0){
                        contractToPayWithProduct.years[year.year] = {
                            uuid: year.uuid
                        }
                    }
                });
                this.productsForPayment.forEach(prod => {
                    if(prod.selected && prod.uuid != 'cash'){
                        contractToPayWithProduct.products.push({
                            uuid: prod.uuid,
                            name: prod.name,
                            quantity: prod.value
                        });
                    }
                });
                this.productPayment(contractToPayWithProduct)
                .then((response) => {
                    this.$emit("hidden");
                    this.$emit("reloadContractPayments");
                    this.$refs.toastMessage.showSuccess('Plata a fost înregistrată cu succes!');
                })
                .catch((e) => {
                    this.$refs.toastMessage.showError("A apărut o erroare la plata cu produs!");
                    console.error('Product payment error: ', e);
                });
            }
        },
        savePaymentWithProductAndCash(){
            if(this.editMode == true){
                this.editPaymentAction();
            }
            else{
                let cashProduct = this.productsForPayment.find(obj => obj.uuid == 'cash');
                if(cashProduct && cashProduct.selected && cashProduct.value && cashProduct.value > 0){
                    this.paymentData.totalValue = cashProduct.value;
                    this.savePayment(true);
                }
                else{
                    this.savePaymentWithProduct();
                }
            }
        }
    },
    mounted(){

    }
}
</script>

<style lang="scss">
  @import "@/assets/scss/rent/payContract.scss";
</style>
