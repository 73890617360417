//roles and permissions
export const ROLES = '/roles';
export const PERMISSIONS = '/permissions';
export const DEFAULT_PERMISSIONS = '/default-permissions';

//public (but, should be logged in)
export const PUBLIC_ROLES = '/public/roles';

//import
export const FILE_UPLOAD_URL = "/import/{company_uuid}/upload/{session_id}"; //+session_id is automatically added
export const FILE_CANCEL_URL = "/import/{company_uuid}/cancel-upload"; //+session_id is automatically added;
export const RECENTLY_UPLOADED_FILES  = "/import/{company_uuid}/recently-uploaded-files";
export const RECENTLY_UPLOADED_ZIP_FILES  = "/import/{company_uuid}/recently-uploaded-zip-files";

//works
export const WORKS = "/works";
export const PARCELS = "/parcels";
export const PERSONS = "/persons";
export const WORK_MACHINES = "/work-machines";
export const CULTURES = "/cultures";
export const CULTURE_VARIETIES = "/culture-varieties";
export const BBCHS = "/fito-culture-bbch-values";
export const WORKS_HAS_WORK_MACHINES = "/works-has-work-machines";

//seasons
export const SEASONS = "/seasons";
export const SEASONS_FILTER = "/seasons/filters";

//layers
export const LAYERS = "/layers";
export const PREVIEW_PARCEL_NAME = "/preview-parcel-name";

//season on parcel has culture
export const SEASON_ON_PARCEL_HAS_CULTURE = "/season-on-parcel-has-culture";
export const SPC_WORK_DETAILS = "/work-details";

//mobile spc works
export const MOBILE_SPC_WORKS = "/mobile/spc/works"
export const MOBILE_SPC_WORK_FILTERS = "/mobile/spc/work/filters"
export const MOBILE_SPC_WORK_SUMMARY = "/mobile/spc/work-summary"

//mobile spc machines
export const MOBILE_SPC_MACHINES = "/mobile/work-machines"
export const MOBILE_SPC_MACHINES_FILTER = "/mobile/work-machines/filter"

//mobile spc parcels
export const MOBILE_SPC_PARCELS = "/mobile/parcels"
export const MOBILE_SPC_PARCEL_FOR_FILTERS = "/mobile/parcel/filters"

//fito products
export const FITO_PRODUCTS = "/fito/products";
export const FITO_PRODUCTS_LIGHT = "/mobile/fito-products";
export const FITO_MEASURE_UNITS = "/fito/measure-units";

//fertility products
export const FERTILITY_PRODUCTS = "/fertility-products";
export const FERTILITY_PRODUCTS_LIGHT = "/mobile/fertility-products";
export const FERTILITY_MEASURE_UNITS = "/fertility/measure-units/search";

//batch
export const BATCH = "/batch";
export const BATCH_USED_PRODUCTS = "/batch/getUsedProducts";
export const BATCH_HEADER_SUMMARY = "/batch/getSummary";
export const BATCH_MEASURE_UNITS = "/batch-measure-units";
export const CREATE_BATCH = "/batch";

//batch-header
export const BATCH_HEADERS = "/batch-headers";
export const DELETE_BATCH_HEADERS = "/batch/{batch_uuid}/headers/{header_uuid}";

//salary calculator
export const SC_BONUS = "/sc/bonuses";
export const SC_BONUS_TYPES = "/sc/bonus-types";
export const SC_BONUS_CALCULATION_TYPES = "/sc/bonus-calculation-types";
export const SC_HOLIDAY = "/sc/holidays";
export const SC_EMPLOYEE = "/sc/employees";
export const SC_EMPLOYEE_ROLE = "/sc/employee-roles";
export const SC_MEASURE_UNIT = "/sc/measure-units";
export const SC_SHIFT = "/sc/shifts";
export const SC_WORK = "/sc/works";
export const SC_WORK_COLLECTOR = "/sc/work-collector";
export const SC_WORK_GROUP = "/sc/work-groups";
export const SC_WORK_PIVOT = "/sc/work-pivots";
export const SC_WORK_COLLECTOR_HECTARE_DATA = "/sc/work-logs-hectare-data";
export const SC_WORK_HIERARCHY = "/sc/work-hierarchy";
export const SC_WORK_LOG = "/sc/work-logs";///sc/work-logs/employees/{employee_uuid}
export const SC_WORK_LOG_EXPORT_EXCEL = "/sc/export/work-logs/excel";
export const SC_WORK_LOG_EMPLOYEE_EXPORT_EXCEL = "/sc/export/employee-works/excel";
export const SC_DOWNLOAD_DAILY_REPORT = "/sc/reports/daily-report";
export const SC_DOWNLOAD_SHR_REPORT = "/sc/reports/shr-report";

//file actions
export const CSV_HEADERS = "/file-service/csv-headers";
export const CSV_HEADERS_QUERY = "/file-service/csv-headers-query";
export const RETRY_CSV_IMPORT = "/file-service/retry-csv-import";

//import config
export const IMPORT_CONFIG_TEMPLATE = "/import-config/template";
export const IMPORT_CONFIG_TEMPLATES = "/import-config/templates";
export const IMPORT_CONFIG_REQUIRED_ATTRIBUTES = "/import-config/required-attributes";
export const IMPORT_CONFIG_CONFIGURATIONS = "/import-config/configurations";
export const IMPORT_CONFIG_SOURCE_CREDENTIALS = "/import-config/source-credentials";
export const IMPORT_CONFIG_VALIDATED_DATA = "/import-config/validated-data";
export const IMPORT_CONFIG_VALIDATED_DATA_LIVE_DATA = "/import-config/validated-data-live-data";

//settings
export const SETTING_GROUPS = "/setting-groups";
export const GROUP_SETTINGS = "/settings/{uuid}";
export const GROUP_SETTING = "/settings/{uuid}/{code}";
export const GROUP_BY_CODE = "/settings/{code}";
export const SETTING_BY_CODE = "/company-settings/{group_code}/{item_code}";

//custom products
export const CUSTOM_PRODUCT = "/custom-products";

//custom product statuses
export const CUSTOM_PRODUCT_STATUS = "/mobile/custom-product-status-types";

//stock management
export const SM_PARTNERS = "/stock-management/partners";
export const SM_PARTNER_TYPES = "/stock-management/partner-types";
export const SM_WAREHOUSES = "/stock-management/warehouses";
export const SM_PRODUCTS = "/stock-management/products";
export const SM_PRODUCT_TYPES = "/stock-management/product-types"
export const SM_PRODUCT_TYPE_SEARCH = "/stock-management/product-type-search"
export const SM_STOCKS = "/stock-management/warehouse-stock"
export const SM_STOCK_PRODUCT_INFO = "/stock-management/warehouse-stock/products"
export const SM_STOCK_PRODUCT_HISTORY = "/stock-management/warehouse-stock-products"
export const SM_PRODUCT_MEASURE_UNITS = "/stock-management/products/measure-units";

export const SM_RECEPTION_TRANSACTION = "/stock-management/transactions/reception"
export const SM_SALES_TRANSACTION = "/stock-management/transactions/sales"
export const SM_CONSUM_TRANSACTION = "/stock-management/transactions/consum"
export const SM_TRANSFER_TRANSACTION = "/stock-management/transactions/transfer"
export const MEASURE_UNITS = "/measure-units";
export const TRANSACTION_RECEPTION = "/stock-management/transactions/reception";

export const PARCEL_CONSUM = "/stock-management/transactions/parcel-consums";
export const PARCEL_CONSUM_SUMMARY = "/stock-management/transactions/parcel-consum-summary";

export const SM_TRANSACTIONS = "/stock-management/transactions"
export const SM_STOCK_MOVEMENTS = "/stock-management/movements"
export const SM_TRANSACTION_TYPES = "/stock-management/transaction-types"
export const SM_STOCK_MOVEMENT_TYPES = "/stock-management/stock-movement-types"

//lease contracts
export const LEASE_CONTRACTS = "/lease-contract"
export const LEASE_CONTRACT_PRODUCTS = "/lease-contract-product"
export const LEASE_CONTRACT_PRODUCT_PRICE = "/lease-contract-product-price"
export const LEASE_CONTRACT_MU = "/lease-contract-measure-units";
export const AREA_OWNERS_FOR_PAYMENT = "/area-owners-for-payment";
export const LEASE_CONTRACT_CASH_PAYMENT = "/lease-contract-cash-payment";
export const LEASE_CONTRACT_PRODUCT_PAYMENT = "/lease-contract-product-payment";
export const LEASE_CONTRACT_PAYMENT = "lease-contract-payment/lease-contract";
export const LEASE_CONTRACT_PAYMENT_EDIT = "lease-contract-payment-edit";
export const LEASE_CONTRACT_TARIFF_SYSTEM = "/lease-contract-tariff-system";
export const LEASE_CONTRACT_TARIFF_SYSTEM_ITEM = "/lc-tariff-system-item";
export const LC_PAYMENT = "/lc-payment";
export const LC_OWNER = "/lease-contract-owner";
export const LC_OWNER_AND_SURFACE_UPDATE = "/lc-area-surface-owner-update";

//scoutings
export const SCOUTINGS = "/scoutings";
export const SCOUTING = "/scouting";

//tools justifying documents - document blocks
export const DOCUMENT_BLOCKS = "/document-blocks";
export const DOCUMENT_BLOCK_CHECK = "/document-block-check";
export const DOCUMENT_BLOCK_MODEL = "/document-block-model";
export const DOCUMENT_TYPES = "/document-types";
export const IS_DOCUMENT_BLOCK_EXISTS = "/document-block-exists";

//agro filter
export const DEPARTMENT_TYPES = '/department-types';
export const DEPARTMENT_FILTER_TYPES = '/department-filter-types';
export const PARENT_DEPARTMENTS = '/parent-departments';
export const DEPARTMENTS = '/departments';

//scouting


export function replaceParams(url, params = []) {
  let replacedURL = url;
  if (params) {
    Object.keys(params).map((key) => {
      if(params[key]) {
        replacedURL = replacedURL.toString().replace('{'+key+'}', params[key]);
      }
    });
  }

  return replacedURL;
}
